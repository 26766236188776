import type { ReactNode } from "react";

import { LayoutWrapper, NavigationProvider } from "@stordco/fe-components";

import { useDistributionNetwork } from "../DistributionNetwork";

import { useMainNav } from "./hooks/useMainNav";
import { useUserMenu } from "./hooks/useUserMenu";

export const Navigation = ({ children }: { children: ReactNode }) => {
  const { distributionNetwork } = useDistributionNetwork();

  const main = useMainNav();
  const userMenu = useUserMenu();

  return (
    <NavigationProvider
      companyName={distributionNetwork.name ?? ""}
      userMenu={userMenu}
      links={main}
    >
      <LayoutWrapper>{children}</LayoutWrapper>
    </NavigationProvider>
  );
};
