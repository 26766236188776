import { useDistributionNetwork } from "../../components/DistributionNetwork";
import { useAuthPermissions } from "./cloudComponents";
import useMe from "./useMe";
import { hasPermission } from "@stordco/cloud-utils";

export const useCloudPermissions = (
  resourcePath: string | string[],
  permission: string,
): [boolean, { isLoading: boolean; error: unknown }] => {
  const {
    distributionNetwork: { tenant_id },
  } = useDistributionNetwork();

  const meQuery = useMe();

  const hasAdminApp = meQuery.data?.data.organizations.some((org) =>
    org.apps.some((app) => app.alias === "oms_admin"),
  );

  const {
    data,
    isLoading: permissionsIsLoading,
    error: permissionsError,
  } = useAuthPermissions(
    {
      pathParams: {
        app: "cloud",
        organization: tenant_id,
      },
    },
    {
      enabled: meQuery.isFetched && !hasAdminApp,
      retry: 0,
    },
  );

  if (typeof resourcePath === "string") {
    resourcePath = resourcePath.split("/");
  }

  const result =
    hasAdminApp ||
    hasPermission(data?.data?.permissions || {}, resourcePath, permission);

  return [
    result,
    {
      isLoading: meQuery.isLoading || permissionsIsLoading,
      error: meQuery.error || permissionsError,
    },
  ];
};
