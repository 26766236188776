import { useMemo } from "react";

import { useUserDetails } from "@stordco/fe-components";
import type { UserContextRole } from "@stordco/fe-components";

import { useDistributionNetwork } from "../components/DistributionNetwork";

// Note: This isn't currently leveraged, but there is an existing affordance in the `can` utility.
// This is duplicated here to make the behavior explicit.
const hasGlobalContext = (contexts: UserContextRole[] = []): boolean =>
  contexts.some(({ type }) => type === "global");
// We currently assume that stord users are all super admins :sweat_smile:. Hopefully this
// is not true in the future.
const isStordUser = (email: string) => email.endsWith("@stord.com");

/**
 * A utility hook that returns true if the user has some or all of the specified permissions depending on the match type.
 *
 * @param permissions An array of permissions to check for.
 * @param match Whether to return true if all specified permissions are found, or if any are found depending on the match type.
 * @returns boolean
 * @example
 * ```ts
 * const hasFullItemPermissions = useHasNetworkPermissions(["admin.item.create", "admin.item.read", "admin.item.update"]);
 * ```
 * @example Has read only item permissions
 * ```ts
 * const hasReadOnlyItemPermissions = useHasNetworkPermissions(["admin.item.read"]);
 * ```
 * @example Has at least read one item permission
 * ```ts
 * const hasReadOneItemPermission = useHasNetworkPermissions(["admin.item.create", "admin.item.read", "admin.item.update"], "some");
 * ```
 *
 */
export const useHasNetworkPermissions = (
  permissions: string[],
  match: "some" | "every" = "every",
) => {
  const { distributionNetwork } = useDistributionNetwork();
  const { data: userDetails } = useUserDetails();

  return useMemo(() => {
    if (!userDetails) return false;

    const { email, contexts } = userDetails;

    if (isStordUser(email) || hasGlobalContext(contexts)) {
      return true;
    }

    const context = contexts.find(
      (ctx) => ctx.id === distributionNetwork.network_id,
    );
    if (!context) return false;

    return permissions[match]((permission) =>
      context.permissions.includes(permission),
    );
  }, [distributionNetwork.network_id, match, permissions, userDetails]);
};
