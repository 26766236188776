import type { PermissionsList, ResourcePermissions } from "./types";

export function hasPermission(
  permissions: PermissionsList | ResourcePermissions,
  path: string[],
  permission: string,
  depth = 1,
) {
  if (Array.isArray(permissions)) {
    if (path.length === 0 || (path.length === 1 && depth % 2 === 0)) {
      return permissions.includes(permission);
    } else {
      return false;
    }
  } else if (typeof permissions === "object") {
    if (path.length === 0) {
      if ("*" in permissions && depth !== 0 && depth % 2 === 0) {
        return hasPermission(permissions["*"], [], permission, depth + 1);
      } else if ("$self" in permissions && depth % 2 === 1) {
        return hasPermission(permissions["$self"], [], permission, depth + 1);
      } else {
        return false;
      }
    } else {
      const [currentResource, ...remainingPath] = path;
      if (
        depth !== 0 &&
        depth % 2 === 0 &&
        "*" in permissions &&
        hasPermission(permissions["*"], remainingPath, permission, depth + 1)
      ) {
        return true;
      }
      return hasPermission(
        permissions[currentResource] || {},
        remainingPath,
        permission,
        depth + 1,
      );
    }
  }
  return false;
}
