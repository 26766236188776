import { isValid, parseISO } from "date-fns";
import { useCallback } from "react";

import type { DateTimeFormatInputs } from "@stordco/fe-components";
import { formatDateTime } from "@stordco/fe-components";

import { useDistributionNetwork } from "../components/DistributionNetwork";

const parseDate = (date: string | number | Date | null) => {
  if (
    !date ||
    // if date is a string parse it before checking if valid
    (typeof date === "string" && !isValid(parseISO(date))) ||
    (typeof date !== "string" && !isValid(date))
  )
    return null;

  if (typeof date === "string") return Date.parse(date);
  return date;
};

/**
 * Returns a function that formats a date string in the preferred timezone
 * Note: Currently uses the distribution network timezone
 */
export const useFormatInPreferredTimezone = () => {
  const { distributionNetwork } = useDistributionNetwork();

  return useCallback(
    (
      // Note: Explicitly using `any` here since we check later if valid
      date: any,
      options: {
        formatStr?: string;
        timeZone?: string;
        type?: DateTimeFormatInputs;
      } = { type: "datetime" },
    ) => {
      const parsedDate = parseDate(date);
      if (parsedDate === null) return null;

      const { formatStr, timeZone, type } = options;

      const networkTimezone = distributionNetwork.timezone ?? "UTC";

      return formatDateTime(
        parsedDate,
        timeZone ?? networkTimezone,
        type,
        formatStr,
      );
    },
    [distributionNetwork.timezone],
  );
};
