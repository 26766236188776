import { useQuery } from "@tanstack/react-query";

import { isBaseError, useApi } from "@stordco/fe-components";

import type { DayOfWeek } from "../../features/NetworkDetails/Facilities/constants";

export const useNetwork = ({ networkId }: { networkId: string }) => {
  const api = useApi();

  return useQuery({
    queryKey: ["networks", networkId],
    queryFn: () => api<{ data: Network }>(`/bff/v1/networks/${networkId}`),
    select: (response) => response.data,
    retry: (failureCount, error) => {
      if (
        isBaseError(error) &&
        (error.status === 404 || error.status === 400)
      ) {
        return false;
      }

      return failureCount < 3;
    },
  });
};

// These types are largely copied from apps/admin/src/hooks/useNetworks.ts
// But with some differences!
export type Network = {
  tenant_id: string;
  network_id: string;
  company_id: string;
  company: Company;
  name: string;
  manual_order_creation: boolean | null;
  stord_email: string;
  trade_connections: TradeConnection[];
  network_configurations: NetworkConfiguration[];
  units_of_measure: UnitOfMeasure[];

  address_id: string | null;
  address: Address | null;
  timezone: string | null;
};

export interface Company {
  company_id: string;
  name: string;

  // There are other fields, add them as needed
  email: string;
  phone: string;
}

export interface NetworkConfiguration<
  MetadataType extends Record<string, any> = Record<string, any>,
> {
  default_enabled: boolean;
  description: string;
  label: string;
  enabled: boolean;
  name: string;
  structure: Object | null;
  metadata: MetadataType;
  type: string | null;
}

interface UnitOfMeasure {
  name: string;
  short_code: string;
}

interface TradeConnection {
  trade_connection_id: string;
  allocated_square_feet: null | string;
  facility: Facility;
  facility_display_name: null | string;
  facility_id: string;
  network_id: string;
  rank: number;
  routing_adder: null | string;
  wms_id: null | string;
  service_level_agreements: ServiceLevelAgreement[];
}

interface ServiceLevelAgreement {
  service_level_agreement_id: string;
  trade_connection_id?: string;
  facility_id?: string;
  fulfillment_type: string;
  cutoff_time: string;
  days_to_complete: number;
  updated_by?: string;
}

interface Facility {
  tenant_id: string;
  facility_id: string;
  address: Address;
  alias: string;
  company_id: string;
  email: null | string;
  facility_display_name: null | string;
  holidays: string[] | null;
  inbound_docks: number | null;
  location_description: null;
  name: string;
  operation_schedules: OperationSchedule[];
  outbound_docks: number | null;
  timezone: string;
  total_square_feet: null | string;
  type: "enf" | "sdn" | "sfn" | "spn" | "warehouse";
  warehouse_management_system_id: null | string;
  wifi_enabled: boolean | null;
}

interface OperationSchedule {
  operation_schedule_id: string;
  facility_id: string;
  day: DayOfWeek;
  start_time: string;
  end_time: string;
}

export interface Address {
  address_id: string;
  address_type: string | null;
  company: string | null;
  country_code: string | null;
  country_subdivision_code: string | null;
  latitude: string | null;
  line1: string | null;
  line2: string | null;
  line3: string | null;
  locality: string | null;
  longitude: string | null;
  name: string | null;
  normalized_address: string | null;
  normalized_country_code: string | null;
  normalized_country_subdivision_code: string | null;
  normalized_line1: string | null;
  normalized_line2: string | null;
  normalized_locality: string | null;
  normalized_name: string | null;
  normalized_postal_code: string | null;
  postal_code: string | null;
  verification_status: string | null;
}
