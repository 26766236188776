import { Flex, type FlexProps } from "@chakra-ui/react";
import type { ReactNode } from "react";

import { ProductImage } from "@stordco/fe-components";

import {
  useNetworkConfigurationFlag,
  useNetworkId,
} from "../DistributionNetwork/useDistributionNetwork";

// If product images is turned off, this will not display the image or the `Flex` around everything.
export const MaybeProductImage = ({
  sku,
  children,
  size,
  gap = 3,
}: {
  sku: string;
  children: ReactNode;
  size?: `${number}px`;
  gap?: FlexProps["gap"];
}) => {
  const hasProductImages = useNetworkConfigurationFlag("show_product_images");
  const networkId = useNetworkId();

  if (!hasProductImages) {
    return <>{children}</>;
  }

  return (
    <Flex gap={gap} alignItems="center">
      <ProductImage
        sku={sku}
        networkId={networkId}
        size={size}
        flexShrink={0}
      />

      {children}
    </Flex>
  );
};
