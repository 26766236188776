import {
  Badge,
  Flex,
  Button,
  useColorModeValue,
  Text,
  Skeleton,
  Center,
} from "@chakra-ui/react";
import { Link as RouterLink } from "react-router-dom";
import { faCircleCheck } from "@fortawesome/pro-regular-svg-icons";
import { faPlug } from "@fortawesome/pro-light-svg-icons";

import { Card, FaIcon, useDatadogRUM } from "@stordco/fe-components";

import { useNetworkId } from "../../../../components/DistributionNetwork";
import { useConnections } from "../../../Connections/ConnectionManagement/common/commonQueries";
import { ConnectionLogo } from "../../../Connections/ConnectionManagement/common/ConnectionLogo";

import { WidgetErrorFetchingData } from "../../WidgetErrorFetchingData";
import { LoadingOverlay } from "../../LoadingOverlay";

export function Connections() {
  const { data: connections, isPending, isError, refetch } = useConnections();
  const networkId = useNetworkId();

  const filteredAndSortedConnections = connections
    ?.filter(
      (connection) =>
        connection.source !== "pipe17" && connection.source !== "logic_broker",
    )
    .sort((a, b) => a.display_name.localeCompare(b.display_name));

  const datadogRUM = useDatadogRUM();

  return (
    <Card>
      <Card.PaddedContent display="flex" flexDirection="column" gap={3}>
        <Flex
          as={RouterLink}
          to={`/distribution_networks/${networkId}/connections`}
          alignItems="center"
          gap={2}
          alignSelf="start"
        >
          <Card.Title>Connections</Card.Title>

          <Badge
            rounded="lg"
            bg={useColorModeValue("gray.900", "gray.100")}
            color={useColorModeValue("white", "black")}
          >
            {filteredAndSortedConnections?.length}
          </Badge>
        </Flex>

        {isPending ? (
          <Flex direction="column">
            {Array.from({ length: 3 }, (...[, index]) => (
              <Skeleton key={index} height="32px" my={1} />
            ))}
          </Flex>
        ) : isError ? (
          <WidgetErrorFetchingData onRetryClick={refetch} />
        ) : filteredAndSortedConnections?.length === 0 ? (
          <Center my={6}>
            <Flex flexDirection="column" alignItems="center" gap={4}>
              <FaIcon color="gray.500" icon={faPlug} size="xl" />

              <Text align="center">
                Once connections exist, they will appear here.
              </Text>
            </Flex>
          </Center>
        ) : (
          <Flex direction="column">
            {filteredAndSortedConnections?.map((connection, index) => {
              const to = `/distribution_networks/${networkId}/connections/${connection.id}`;

              return (
                <Button
                  key={index}
                  as={RouterLink}
                  to={to}
                  variant="ghost"
                  colorScheme="gray"
                  height="auto"
                  justifyContent="start"
                  gap={2}
                  px={2}
                  mx={-2}
                  py={2}
                  onClick={() => {
                    datadogRUM?.addAction("dashboard:view_connection", {
                      connection,
                    });
                  }}
                >
                  <ConnectionLogo boxSize={6} source={connection.source} />

                  <Text noOfLines={1}>{connection.display_name}</Text>

                  <FaIcon
                    icon={faCircleCheck}
                    color="green.600"
                    _dark={{ color: "green.200" }}
                    ml="auto"
                  />
                </Button>
              );
            })}
          </Flex>
        )}

        {isPending ? <LoadingOverlay /> : null}
      </Card.PaddedContent>
    </Card>
  );
}
