import { Navigate } from "react-router-dom";

import { useUserDetails, useConfig } from "@stordco/fe-components";
import type { UserDetails } from "@stordco/fe-components";

const getFirstDistributionNetwork = (userDetails: UserDetails) => {
  // Note: this is not the right solution - we can't guarantee that the network someone wants is the first one in the list.
  // This would really be better handled by showing a list of organizations

  // Leave for readability =)
  const v1Type = "distributionNetwork";
  const v2Type = "distribution_network";
  const context = (userDetails.contexts || []).find((context) => {
    return [v1Type, v2Type].includes(context.type);
  });

  return context?.id;
};

const redirectTo = (url: string): null => {
  window.location.assign(url);
  return null;
};

/**
 * @param intendedUrl - if the user passes all the checks, this is the URL we want to access
 */
const RedirectUser = ({ intendedUrl = "" }: { intendedUrl?: string }) => {
  const { ADMIN_HOST } = useConfig();
  const { data: userDetails } = useUserDetails();

  const networkId = getFirstDistributionNetwork(userDetails);

  // TODO: handle setup later
  if (userDetails.status === "invited") {
    return <Navigate to={`/onboarding`} />;
  }

  if (networkId) {
    return (
      <Navigate to={`/distribution_networks/${networkId}` + intendedUrl} />
    );
  }

  if (userDetails.isAdmin) {
    return redirectTo(ADMIN_HOST);
  }

  return <Navigate to={"/forbidden"} />;
};

export default RedirectUser;
