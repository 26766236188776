import { can } from "../components/Authorization";

import { useUserDetails } from "./useUserDetails";

const useCan = (
  permission: string,
  contextIds: Array<string> = [],
): boolean => {
  const { data: userDetails } = useUserDetails();

  if (!userDetails) {
    return false;
  }

  return can(userDetails, permission, contextIds);
};

export default useCan;
