import type { SetupWorkerApi } from "msw";
import { Fragment, Suspense } from "react";
import { createRoot } from "react-dom/client";

import { PageLoader } from "@stordco/fe-components";

import { App } from "./components/App";

import "./index.css";

// Turbo will often publish a cached preview environment, so check the hostname to see if we're in a preview or dev environment
const isDevOrPreview =
  window.location.hostname === "localhost" ||
  window.location.hostname.includes("pages.dev");

const prepareWorker = async () => {
  if (isDevOrPreview) {
    const { MSWToolbar } = await import("@stordco/msw-toolbar");
    const { setupWorker } = await import("msw");
    const {
      bffOrdersIndexMockHandler,
      bffOrderDetailsMockHandler,
      facilityActivityDetailsMockHandler,
      networkResponsesV2MockHandler,
      bffQualifierTrackingMockHandler,
      inventoryResponseV2MockHandler,
      bffUserActivateMockHandler,
      territoriesMockHandler,
      bffUsersMockHandler,
      bffDeactivateUserMockHandler,
      searchItemHandler,
      getChannelsHandler,
      bffResendInvitationMockHandler,
      // documentsHandler,
      inviteUsersMockHandler,
      bffCancelUserMockHandler,
      bffResetPasswordMockHandler,
      bffUpdateUserProfileMockHandler,
      bffReactivateUserMockHandler,
      getTradeConnectionsHandler,
      createOrderHandler,
      createTransferOrderHandler,
      facilityAvailabilityHandler,
      facilityAvailabilityHandlerV2,
      signedUrlsMockHandler,
      bffRolesMockHandler,
      bffInviteUsersV2MockHandler,
      bffUpdateUserRoleMockHandler,
      getTradeConnectionHandler,
      shipmentConfirmationCancellationHandler,
      editOrderLines400Handler,
      timezones,
      cancelOrderHandlerWMSCancellationFailure,
      bffItemsValidate422SkuAlreadyTakenHandler,
      bffWorkOrdersHandler,
      activityLog,
      orderRoutingStrategyHandler,
      deliveredOrderStatusMockHandler,
      inventoryPlanningTrendsHandler,
      cxAlertTypesHandler,
    } = await import("./handlers");

    const worker = setupWorker(
      ...[
        deliveredOrderStatusMockHandler,
        bffOrdersIndexMockHandler,
        bffOrderDetailsMockHandler,
        facilityActivityDetailsMockHandler,
        networkResponsesV2MockHandler,
        bffQualifierTrackingMockHandler,
        inventoryResponseV2MockHandler,
        bffUserActivateMockHandler,
        territoriesMockHandler,
        bffUsersMockHandler,
        bffDeactivateUserMockHandler,
        searchItemHandler,
        getChannelsHandler,
        inviteUsersMockHandler,
        bffCancelUserMockHandler,
        bffResendInvitationMockHandler,
        bffResetPasswordMockHandler,
        bffUpdateUserProfileMockHandler,
        bffReactivateUserMockHandler,
        signedUrlsMockHandler,
        getTradeConnectionsHandler,
        createOrderHandler,
        createTransferOrderHandler,
        facilityAvailabilityHandler,
        facilityAvailabilityHandlerV2,
        bffRolesMockHandler,
        bffInviteUsersV2MockHandler,
        bffUpdateUserRoleMockHandler,
        getTradeConnectionHandler,
        shipmentConfirmationCancellationHandler,
        editOrderLines400Handler,
        timezones,
        cancelOrderHandlerWMSCancellationFailure,
        bffItemsValidate422SkuAlreadyTakenHandler,
        bffWorkOrdersHandler,
        activityLog,
        orderRoutingStrategyHandler,
        inventoryPlanningTrendsHandler,
        cxAlertTypesHandler,
      ].flat(),
    );

    return { worker, MSWToolbar };
  }

  return { worker: null, MSWToolbar: Fragment };
};

const container = document.getElementById("root");
const root = createRoot(container!);

async function renderApp({
  worker,
  MSWToolbar,
}: {
  worker: SetupWorkerApi | null;
  MSWToolbar: any;
}) {
  root.render(
    <Suspense fallback={<PageLoader />}>
      <MSWToolbar
        worker={worker}
        isEnabled={isDevOrPreview}
        apiUrl={process.env.NODE_ENV === "dev" ? "/api" : null}
        prefix="shipper"
        style={{ zIndex: 9999 }}
      >
        <App />
      </MSWToolbar>
    </Suspense>,
  );
}

prepareWorker().then(({ worker, MSWToolbar }) => {
  renderApp({ worker, MSWToolbar });
});
