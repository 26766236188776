import { Flex, Image as ChakraImage, type FlexProps } from "@chakra-ui/react";
import { useMemo } from "react";

import { StordLogoIconBadge } from "../StordLogo";
import { useApi } from "../../utils";

export const ProductImage = ({
  sku,
  networkId,
  size = "32px",
  alt,
  ...props
}: FlexProps & {
  sku: string;
  networkId: string;
  size?: `${number}px`;
  alt?: string;
}) => {
  const api = useApi();

  const src = useMemo(() => {
    const src = api.resolveApiUrl(
      `/networks/${networkId}/items/${encodeURIComponent(sku)}/image`,
    );

    if (size) {
      const sizeAsNumber = parseInt(size);

      const params = new URLSearchParams({
        width: String(sizeAsNumber * window.devicePixelRatio),
        height: String(sizeAsNumber * window.devicePixelRatio),
      });

      return `${src}?${params}`;
    }

    return src;
  }, [api, networkId, size, sku]);

  return (
    <Flex
      width={size}
      aspectRatio={1}
      borderRadius="base"
      overflow="hidden"
      {...props}
    >
      <ChakraImage
        src={src}
        alt={alt}
        fit="contain"
        fallback={<StordLogoIconBadge boxSize={size} colorScheme="gray" />}
      />
    </Flex>
  );
};
