import debounce from "lodash/debounce.js";
import { useEffect, useMemo, useState } from "react";
import type { Control, UseControllerProps } from "react-hook-form";
import { Controller } from "react-hook-form";
import { useController, useWatch } from "react-hook-form";
import type { OptionProps } from "react-select";
import {
  FormControl,
  FormErrorMessage,
  InputGroup,
  InputRightAddon,
  usePrevious,
} from "@chakra-ui/react";
import {
  parseAbsolute,
  toCalendarDate,
  toZoned,
} from "@internationalized/date";

import {
  AsyncSelect,
  Creatable,
  DatePicker,
  DateRangePicker,
  OurSelectOption,
  RHFCurrencyInput,
  RHFInput,
  RHFNumberInput,
  RHFSelect,
  StackedCell,
} from "@stordco/fe-components";

import type { Product } from "../../../hooks";
import { useChannels, useTerritories, useSubdivisions } from "../../../hooks";
import { useDistributionNetwork } from "../../../components/DistributionNetwork";
import type { ProductListing } from "../../ProductCatalog/ProductListings/queries";
import { useProductListing } from "../../ProductCatalog/ProductListings/queries";
import { useProductListingSearch } from "../../ProductCatalog/ProductListings/queries";
import type { AutomationFormValues, ConditionOperator } from "../types";
import { ProductSelect } from "../../../components/ProductSelect/ProductSelect";
import { MaybeProductImage } from "../../../components/MaybeProductImage";
import { useItemDetails } from "../../../hooks/useItemDetails";

export interface DynamicSelectorProps {
  currentIndex?: number;
  control: Control<AutomationFormValues>;
  isDisabled: boolean;
  name: UseControllerProps<AutomationFormValues>["name"];
  label: string;
}

export interface MultiValueTagLabelProps {
  value: string | number;
}

const FIELD_REQUIRED = { required: "Field is required" };

export const ListingSelector = (props: DynamicSelectorProps) => {
  const listingSearch = useProductListingSearch();
  const [searchOptions, setSearchOptions] = useState<ProductListing[]>([]);

  const loadOptions = debounce(
    (inputValue: string, callback: (options: ProductListing[]) => void) => {
      listingSearch({
        search_field: "listing",
        search_term: inputValue,
      }).then(({ data }) => {
        const options = data.sort((a, b) => a.listing.localeCompare(b.listing));
        setSearchOptions(options);
        callback(options);
      });
    },
    250,
  );

  return (
    <Controller
      control={props.control}
      name={props.name}
      rules={FIELD_REQUIRED}
      render={({ field, fieldState }) => (
        <FormControl isInvalid={Boolean(fieldState.error)}>
          <AsyncSelect<ProductListing, false>
            onChange={(listing) => {
              if (!listing) {
                field.onChange(null);
              } else {
                field.onChange(listing.id);
              }
            }}
            loadOptions={loadOptions}
            defaultOptions
            noOptionsMessage={({ inputValue }) =>
              inputValue ? "No results" : "Type to search available options"
            }
            value={
              field.value
                ? searchOptions.find((listing) => listing.id === field.value)
                : null
            }
            isDisabled={props.isDisabled}
            isSearchable
            components={{ Option: ListingOption }}
            getOptionLabel={(listing) =>
              `${listing.listing} - ${listing.name} (${listing.channel_name})`
            }
            getOptionValue={(listing) => listing.id}
            menuPortalTarget={document.body}
            placeholder="Enter listing SKU"
          />

          <FormErrorMessage>{fieldState.error?.message}</FormErrorMessage>
        </FormControl>
      )}
    />
  );
};

export const MultiListingSelector = (props: DynamicSelectorProps) => {
  const listingSearch = useProductListingSearch();

  const loadOptions = useMemo(
    () =>
      debounce(
        (inputValue: string, callback: (options: ProductListing[]) => void) => {
          listingSearch({
            search_field: "listing",
            search_term: inputValue,
          }).then(({ data }) => {
            const options = [...data].sort((a, b) =>
              a.listing.localeCompare(b.listing),
            );

            callback(options);
          });
        },
        250,
      ),
    [listingSearch],
  );

  return (
    <Controller
      control={props.control}
      name={props.name}
      rules={FIELD_REQUIRED}
      render={({ field, fieldState }) => (
        <FormControl isInvalid={Boolean(fieldState.error)}>
          <AsyncSelect<ProductListing, true>
            onChange={(listings) => {
              field.onChange(listings.map((listing) => listing.id));
            }}
            loadOptions={loadOptions}
            defaultOptions
            noOptionsMessage={({ inputValue }) =>
              inputValue ? "No results" : "Type to search available options"
            }
            value={
              (field.value as string[])?.map(
                (listingId) =>
                  ({
                    id: listingId,
                  }) as ProductListing,
              ) ?? null
            }
            isOptionSelected={(option, selectValue) =>
              selectValue.some((listing) => listing.id === option.id)
            }
            isDisabled={props.isDisabled}
            isSearchable
            getOptionLabel={(listing) =>
              `${listing.listing} - ${listing.name} (${listing.channel_name})`
            }
            getOptionValue={(listing) => listing.id}
            menuPortalTarget={document.body}
            isMulti
            closeMenuOnSelect={false}
            controlShouldRenderValue={false}
            placeholder={
              (field.value as string[])?.length
                ? `${(field.value as string[]).length} selected`
                : "Enter listing SKU"
            }
            hideSelectedOptions
          />

          <FormErrorMessage>{fieldState.error?.message}</FormErrorMessage>
        </FormControl>
      )}
    />
  );
};

export function ListingMultiValueTagLabel({ value }: MultiValueTagLabelProps) {
  const { data: listing } = useProductListing(value as string);

  return listing?.listing;
}

function ListingOption(props: OptionProps<ProductListing>) {
  return (
    <OurSelectOption {...props}>
      <StackedCell
        lines={[
          props.data.listing,
          `${props.data.name} (${props.data.channel_name})`,
        ]}
      />
    </OurSelectOption>
  );
}

export const SkuSelector = (props: DynamicSelectorProps) => {
  const [selectedProduct, setSelectedProduct] = useState<Product | null>(null);

  const { field, fieldState } = useController({
    control: props.control,
    name: props.name,
    rules: FIELD_REQUIRED,
  });

  const { data: itemDetails } = useItemDetails({
    sku: field.value as string,
    enabled: Boolean(field.value),
  });

  return (
    <FormControl isInvalid={Boolean(fieldState.error)}>
      <ProductSelect
        value={
          // If we've selected a Product, we immediately have everything we need to show the label
          selectedProduct
            ? selectedProduct
            : // If we've loaded item details, we can show the label
            itemDetails
            ? { ...itemDetails, id: itemDetails?.item_id, type: "item" }
            : // If there's a value
            field.value
            ? // Provide a fake Product so we can at least show something while the details load
              ({ sku: field.value } as Product)
            : null
        }
        onChange={(product) => {
          setSelectedProduct(product);
          field.onChange(product!.sku);
        }}
        isDisabled={props.isDisabled}
        placeholder="Enter SKU or name"
        isOptionSelected={(option, selectValue) =>
          selectValue.some((product) => product.sku === option.sku)
        }
      />

      <FormErrorMessage>{fieldState.error?.message}</FormErrorMessage>
    </FormControl>
  );
};

export const MultiSkuSelector = (props: DynamicSelectorProps) => {
  return (
    <Controller
      control={props.control}
      name={props.name}
      rules={FIELD_REQUIRED}
      render={({ field, fieldState }) => (
        <FormControl isInvalid={Boolean(fieldState.error)}>
          <ProductSelect
            value={(field.value as string[])?.map(
              (sku) =>
                ({
                  sku,
                }) as Product,
            )}
            onChange={(products) => {
              field.onChange(products.map((product) => product.sku));
            }}
            isDisabled={props.isDisabled}
            isOptionSelected={(option, selectValue) =>
              selectValue.some((product) => product.sku === option.sku)
            }
            isMulti
            placeholder={
              (field.value as string[])?.length
                ? `${(field.value as string[]).length} selected`
                : "Enter SKU or name"
            }
            closeMenuOnSelect={false}
            controlShouldRenderValue={false}
          />

          <FormErrorMessage>{fieldState.error?.message}</FormErrorMessage>
        </FormControl>
      )}
    />
  );
};

export function SkuMultiValueTagLabel({ value }: MultiValueTagLabelProps) {
  return (
    <MaybeProductImage sku={value as string} size="16px" gap={2}>
      {value}
    </MaybeProductImage>
  );
}

export const ChannelSelector = (props: DynamicSelectorProps) => {
  const { data, isLoading } = useChannels();

  const options = useMemo(() => {
    return (
      data?.map((channel) => ({
        label: channel.display_name,
        value: channel.channel_id,
      })) ?? []
    );
  }, [data]);

  return (
    <RHFSelect
      {...props}
      hideLabel
      isSearchable
      rules={FIELD_REQUIRED}
      options={options}
      isLoading={isLoading}
    />
  );
};

export function MultiChannelSelector(props: DynamicSelectorProps) {
  const { data, isLoading } = useChannels();

  const options = useMemo(() => {
    return (
      data?.map((channel) => ({
        label: channel.display_name,
        value: channel.channel_id,
      })) ?? []
    );
  }, [data]);

  return (
    <RHFSelect
      {...props}
      hideLabel
      isSearchable
      rules={FIELD_REQUIRED}
      options={options}
      isLoading={isLoading}
      closeMenuOnSelect={false}
      isMulti
      numSelectedPlaceholder
    />
  );
}

export function ChannelMultiValueTagLabel({ value }: MultiValueTagLabelProps) {
  const { data: channels } = useChannels();

  return (
    channels?.find((channel) => channel.channel_id === value)?.display_name ??
    value
  );
}

export const StateSelector = (props: DynamicSelectorProps) => {
  const { data: subdivisions, isFetching } = useSubdivisions("us");

  const options = useMemo(
    () =>
      (subdivisions ?? [])?.map((state) => ({
        label: state.label,
        value: state.subdivision_code,
      })),
    [subdivisions],
  );

  return (
    <RHFSelect
      {...props}
      hideLabel
      isSearchable
      rules={FIELD_REQUIRED}
      options={options}
      isLoading={isFetching}
      menuPortalTarget={document.body}
    />
  );
};

export const MultiStateSelector = (props: DynamicSelectorProps) => {
  const { data: subdivisions, isFetching } = useSubdivisions("us");

  const options = useMemo(
    () =>
      (subdivisions ?? [])?.map((state) => ({
        label: state.label,
        value: state.subdivision_code,
      })),
    [subdivisions],
  );

  return (
    <RHFSelect
      {...props}
      hideLabel
      isSearchable
      rules={FIELD_REQUIRED}
      options={options}
      isLoading={isFetching}
      menuPortalTarget={document.body}
      isMulti
      closeMenuOnSelect={false}
      numSelectedPlaceholder
    />
  );
};

export function StateMultiValueTagLabel({ value }: MultiValueTagLabelProps) {
  const { data: subdivisions } = useSubdivisions("us");

  return (
    subdivisions?.find((subdivision) => subdivision.subdivision_code === value)
      ?.label ?? value
  );
}

export const CountrySelector = (props: DynamicSelectorProps) => {
  const { data: territories, isFetching } = useTerritories();

  const options = useMemo(
    () =>
      (territories ?? []).map((country) => ({
        label: country.label,
        value: country.territory_code,
      })) ?? [],
    [territories],
  );

  return (
    <RHFSelect
      {...props}
      hideLabel
      isSearchable
      options={options}
      rules={{ required: "Field is required" }}
      isLoading={isFetching}
      menuPortalTarget={document.body}
    />
  );
};

export const MultiCountrySelector = (props: DynamicSelectorProps) => {
  const { data: territories, isFetching } = useTerritories();

  const options = useMemo(
    () =>
      (territories ?? []).map((country) => ({
        label: country.label,
        value: country.territory_code,
      })) ?? [],
    [territories],
  );

  return (
    <RHFSelect
      {...props}
      hideLabel
      isSearchable
      options={options}
      rules={{ required: "Field is required" }}
      isLoading={isFetching}
      menuPortalTarget={document.body}
      isMulti
      closeMenuOnSelect={false}
      numSelectedPlaceholder
    />
  );
};

export function CountryMultiValueTagLabel({ value }: MultiValueTagLabelProps) {
  const { data: territories } = useTerritories();

  return (
    territories?.find((territory) => territory.territory_code === value)
      ?.label ?? value
  );
}

export const ChannelCategorySelector = (props: DynamicSelectorProps) => (
  <RHFSelect
    isSearchable
    {...props}
    hideLabel
    rules={{ required: "Field is required" }}
    options={[
      { label: "B2B", value: "B2B" },
      { label: "D2C", value: "D2C" },
    ]}
    menuPortalTarget={document.body}
  />
);

export const MultiChannelCategorySelector = (props: DynamicSelectorProps) => (
  <RHFSelect
    isSearchable
    {...props}
    hideLabel
    rules={{ required: "Field is required" }}
    options={[
      { label: "B2B", value: "B2B" },
      { label: "D2C", value: "D2C" },
    ]}
    menuPortalTarget={document.body}
    isMulti
    closeOnSelect={false}
    numSelectedPlaceholder
  />
);

export function MultiTagSelector({ control, name }: DynamicSelectorProps) {
  return (
    <Controller
      control={control}
      name={name}
      render={({ field, fieldState }) => (
        <FormControl isInvalid={Boolean(fieldState.error)}>
          <Creatable
            value={(field.value as string[]).map((tag) => ({
              label: tag,
              value: tag,
            }))}
            onChange={(newValue) => {
              field.onChange(newValue.map(({ value }) => value));
            }}
            isMulti
            controlShouldRenderValue={false}
            hideDropdownIndicator
            placeholder={
              (field.value as string[]).length
                ? `${(field.value as string[]).length} selected`
                : ""
            }
            noOptionsMessage={() => "Type to add a tag"}
            formatCreateLabel={(inputValue) => `Add '${inputValue}'`}
            closeMenuOnSelect={false}
          />
        </FormControl>
      )}
    />
  );
}

export const BaseSelector = (props: DynamicSelectorProps) => (
  <RHFSelect
    {...props}
    hideLabel
    rules={{ required: "Field is required" }}
    options={[
      {
        label: "in the order",
        value: true,
      },
    ]}
    menuPortalTarget={document.body}
  />
);

export const TextInput = ({
  control,
  name,
  isDisabled,
  label,
}: DynamicSelectorProps) => (
  <RHFInput
    control={control}
    hideLabel
    isDisabled={isDisabled}
    label={label}
    name={name}
    rules={{ required: "Field is required" }}
  />
);

export const WeightInput = ({
  control,
  name,
  currentIndex,
  isDisabled,
  label,
}: DynamicSelectorProps) => {
  return (
    <InputGroup justifyContent="center">
      <RHFInput
        isDisabled={isDisabled}
        rules={{
          required: "Field is required",
          min: {
            value: 0,
            message: "Must be greater than 0",
          },
        }}
        borderRightRadius="none"
        control={control}
        hideLabel
        label={label}
        name={name}
        type="number"
        textAlign="right"
      />
      <InputRightAddon px="0" border="none">
        <RHFSelect
          control={control}
          defaultValue="lb"
          label="Operand unit"
          hideCheckIcon
          hideLabel
          isDisabled={isDisabled}
          isSearchable={false}
          name={`condition_configs.${currentIndex!}.operand.meta.unit`}
          rules={FIELD_REQUIRED}
          options={[
            { label: "lb", value: "lb" },
            { label: "oz", value: "oz" },
            { label: "kg", value: "kg" },
          ]}
          addOnDirection="right"
          menuPortalTarget={document.body}
        />
      </InputRightAddon>
    </InputGroup>
  );
};

const priorityOptions = [
  { label: "Low", value: 1 },
  { label: "Medium", value: 2 },
  { label: "High", value: 3 },
];

export const PrioritySelector = (props: DynamicSelectorProps) => (
  <RHFSelect
    isSearchable
    {...props}
    hideLabel
    rules={{ required: "Field is required" }}
    options={priorityOptions}
    menuPortalTarget={document.body}
  />
);

export const MultiPrioritySelector = (props: DynamicSelectorProps) => (
  <RHFSelect
    isSearchable
    {...props}
    hideLabel
    rules={{ required: "Field is required" }}
    options={priorityOptions}
    menuPortalTarget={document.body}
    isMulti
    closeOnSelect={false}
    numSelectedPlaceholder
  />
);

export function PriorityMultiValueTagLabel({ value }: MultiValueTagLabelProps) {
  return priorityOptions.find((option) => option.value === value)?.label;
}

/**
 * \\ BEWARE -- HERE BE DRAGONS //
 * The API can either return a single date (when condition is `eq`, `lt`, `gt/e`) or
 * an array that is a range (`between`). This component handles both cases.
 * The API also returns dates in UTC, but we need to convert them to the timezone
 * of the distribution network.
 *
 * No matter what the user picks, the value will be converted to UTC in the `onChange`
 * and converted back to display the value so that we can just pass the form values to the API.
 * If a user switches between a single date and range of dates, we make that value `null`.
 */
export const DateInput = ({
  currentIndex,
  control,
  name,
  label,
  isDisabled,
}: DynamicSelectorProps) => {
  const { distributionNetwork } = useDistributionNetwork();
  const timezone = distributionNetwork.timezone;

  const selectedCondition = useWatch({
    control,
    name: `condition_configs.${currentIndex!}.operator`,
  });

  const previousCondition = usePrevious(selectedCondition);

  const {
    field: { onChange, value },
  } = useController({
    control,
    name,
  });

  useEffect(() => {
    /* When the selected condition changes, we want to clear the value
     * so that we don't send the wrong value to the API.
     * No need to reset when switching between single date types.
     */
    if (
      (selectedCondition === "between" && previousCondition !== "between") ||
      (previousCondition === "between" && selectedCondition !== "between")
    ) {
      onChange(null);
    }
  }, [previousCondition, selectedCondition, onChange]);

  if (selectedCondition === "between") {
    const [start, end] = Array.isArray(value) ? value : [];

    return (
      <DateRangePicker
        // Converts to an array of `[startDate, endDate]`. `endDate` must be end of day.
        onChange={({ start, end }) => {
          onChange([
            start?.toDate(timezone).toISOString(),
            toZoned(end, timezone)
              .set({ hour: 23, minute: 59, second: 59 })
              .toAbsoluteString(),
          ]);
        }}
        value={
          start && end
            ? {
                start: toCalendarDate(parseAbsolute(start as string, timezone)),
                end: toCalendarDate(parseAbsolute(end as string, timezone)),
              }
            : null
        }
        label={label}
        hideLabel
        isDisabled={isDisabled}
      />
    );
  } else {
    return (
      <DatePicker
        // Convert to local midnight in UTC before sending to API
        onChange={(value) => {
          if (value) {
            onChange(value.toDate(timezone).toISOString());
          } else {
            onChange(null);
          }
        }}
        value={
          value
            ? Array.isArray(value)
              ? null
              : toCalendarDate(parseAbsolute(value as string, timezone))
            : null
        }
        label={label}
        hideLabel
        isDisabled={isDisabled}
      />
    );
  }
};

export const TimeInput = ({
  control,
  name,
  currentIndex,
  isDisabled,
  label,
}: DynamicSelectorProps) => {
  return (
    <InputGroup justifyContent="center">
      <RHFInput
        isDisabled={isDisabled}
        rules={{
          required: "Field is required",
          min: {
            value: 0,
            message: "Must be greater than 0",
          },
        }}
        borderRightRadius="none"
        control={control}
        hideLabel
        label={label}
        name={name}
        type="number"
        textAlign="right"
      />
      <InputRightAddon px={0} border="none">
        <RHFSelect
          control={control}
          defaultValue="hour"
          label="Operand unit"
          hideCheckIcon
          hideLabel
          isDisabled={isDisabled}
          isSearchable={false}
          name={`condition_configs.${currentIndex!}.operand.meta.unit`}
          rules={FIELD_REQUIRED}
          options={[
            { label: "minute(s)", value: "minute" },
            { label: "hour(s)", value: "hour" },
            { label: "day(s)", value: "day" },
          ]}
          addOnDirection="right"
          menuPortalTarget={document.body}
        />
      </InputRightAddon>
    </InputGroup>
  );
};

export const NumberInput = ({
  control,
  name,
  isDisabled,
  label,
}: DynamicSelectorProps) => (
  <RHFNumberInput
    control={control}
    isDisabled={isDisabled}
    label={label}
    hideLabel
    name={name}
    width="100%"
  />
);

export const PriceInput = ({
  control,
  name,
  isDisabled,
  label,
}: DynamicSelectorProps) => (
  <RHFCurrencyInput
    control={control}
    name={name}
    isDisabled={isDisabled}
    label={label}
    hideLabel
    rules={{ required: true }}
  />
);

export const OPERATORS: Record<
  ConditionOperator,
  { label: string; dateLabel?: string; isMulti?: true }
> = {
  contains: { label: "contains" },
  includes: { label: "include(s)" },
  eq: { label: "is", dateLabel: "is on" },
  not_eq: { label: "is not" },
  lt: { label: "is less than", dateLabel: "is before" },
  gt: { label: "is greater than", dateLabel: "is after" },
  gt_eq: { label: "is greater than or equals", dateLabel: "is on or after" },
  between: { label: "between", dateLabel: "is between" },
  is_any_of: { label: "is any of", isMulti: true },
  is_none_of: { label: "is none of", isMulti: true },
  are_any_of: { label: "are any of", isMulti: true },
  are_all_of: { label: "are all of", isMulti: true },
  are_none_of: { label: "are none of", isMulti: true },
};
