import type { ReactNode } from "react";
import { useEffect } from "react";

import { useUserDetails, useUserflow } from "@stordco/fe-components";

import LaunchDarklyCustomUserAttributes from "../LaunchDarklyCustomUserAttributes";
import { useDistributionNetwork } from "../DistributionNetwork";

export const TrackersLoader = ({ children }: { children: ReactNode }) => {
  const { userflow } = useUserflow();
  const { data: userDetails } = useUserDetails();

  const { distributionNetwork } = useDistributionNetwork();

  const context = (userDetails?.contexts || []).find(
    (ctx) => ctx.type === "distribution_network",
  );

  const role = context?.roles[0];

  useEffect(() => {
    if (userflow?.isIdentified()) {
      userflow.updateUser({
        name: `${userDetails.firstName} ${userDetails.lastName}`,
        email: userDetails.email,
        company: distributionNetwork.company.name,
        version: "v2",
        networkName: distributionNetwork.name,
        networkId: distributionNetwork.network_id,
        role,
      });
    }
  }, [
    distributionNetwork.company.name,
    distributionNetwork.name,
    distributionNetwork.network_id,
    role,
    userDetails.email,
    userDetails.firstName,
    userDetails.lastName,
    userflow,
  ]);

  return (
    <LaunchDarklyCustomUserAttributes>
      {children}
    </LaunchDarklyCustomUserAttributes>
  );
};
