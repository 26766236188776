import type { HelmetProps } from "react-helmet-async";
import type { ReactNode } from "react";

import { SEO as BaseSEO, useUserDetails } from "@stordco/fe-components";

import { useDistributionNetwork } from "../DistributionNetwork";

/**
 * Wrapper around SEO that injected the distribution network name into the title for Stord Admins
 * @see https://github.com/nfl/react-helmet for more info on the Helmet component
 * @see https://www.npmjs.com/package/react-helmet-async for fork-specific info
 */
export const SEO = (props: HelmetProps & { children?: ReactNode }) => {
  const network = useDistributionNetwork();
  const { data } = useUserDetails();

  // Make it easy for an admin to know which network the tab is for
  const titleTemplate = data?.isAdmin
    ? `%s (${network.distributionNetwork.name}) | Stord OMS`
    : "%s | Stord OMS";

  return (
    <BaseSEO
      defaultTitle="Stord OMS"
      titleTemplate={titleTemplate}
      {...props}
    />
  );
};
