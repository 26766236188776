import {
  Flex,
  useColorModeValue,
  useTheme,
  type CenterProps,
  useToken,
} from "@chakra-ui/react";
import { transparentize } from "@chakra-ui/theme-tools";

import { StordEmblem } from ".";

export type StordLogoIconBadgeProps = CenterProps & { colorScheme?: "gray" };
export const StordLogoIconBadge = ({
  bgColor,
  color,
  colorScheme,
  ...props
}: StordLogoIconBadgeProps) => {
  const theme = useTheme();

  const { bgColor: colorSchemeBgColor, color: colorSchemeColor } = {
    gray: {
      bgColor: useColorModeValue(
        "gray.50",
        transparentize("gray.200", 0.16)(theme),
      ),
      color: useColorModeValue("gray.300", "gray.700"),
    },
    default: {
      bgColor: "black",
      color: "white",
    },
  }[colorScheme ?? "default"];

  const fill = useToken("colors", (color ?? colorSchemeColor) as string);

  return (
    <Flex
      justifyContent="center"
      alignItems="center"
      borderRadius="base"
      bgColor={bgColor ?? colorSchemeBgColor}
      flexShrink={0}
      data-testid="stord-logo"
      {...props}
    >
      <StordEmblem width="62.5%" height="62.5%" fill={fill} />
    </Flex>
  );
};
