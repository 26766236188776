import { useCloudApi } from "@stordco/fe-components";
import { useQuery } from "@tanstack/react-query";

export default function useMe() {
  const cloudApi = useCloudApi();

  return useQuery({
    queryKey: ["CloudMeShipper"],
    queryFn: async () => {
      return cloudApi.get<MeResponse>({
        url: "/v1/me",
        params: {
          ["filter[app_aliases][]"]: ["oms", "oms_admin"],
        },
      });
    },
  });
}

interface MeResponse {
  data: Data;
}

interface Data {
  organizations: Organization[];
  type: string;
  user: User;
}

interface User {
  email: string;
  external_id: string;
  first_name: string;
  id: string;
  last_name: string;
  phone?: any;
  status: string;
}

interface Organization {
  alias: string;
  apps: App[];
  description?: any;
  id: string;
  name: string;
}

interface App {
  alias: string;
  base_url: string;
  description?: any;
  id: string;
  name: string;
  realms: Realm[];
}

interface Realm {
  description?: any;
  full_resource_name: string;
  name: string;
  resource_id: string;
  resource_path: string;
}
