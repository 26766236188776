import type { ReactNode } from "react";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

import {
  isBaseError,
  PageLoader,
  useConfig,
  useApiHeaders,
} from "@stordco/fe-components";

import { SEO } from "../SEO";

import DistributionNetworkContext from "./DistributionNetworkContext";
import type { Network } from "./useNetwork";
import { useNetwork } from "./useNetwork";

const DistributionNetworkProvider = ({ children }: { children: ReactNode }) => {
  const { id: networkId } = useParams();
  const { SHIPPER_V1_HOST } = useConfig();
  const navigate = useNavigate();

  const { set: setHeader } = useApiHeaders();
  const networkQuery = useNetwork({
    networkId: networkId!,
  });

  useEffect(() => {
    if (networkQuery.data) {
      setHeader("tenant-id", networkQuery.data.tenant_id);
      setHeader("x-network-id", networkQuery.data.network_id);
    }
  }, [networkQuery.data, setHeader]);

  useEffect(() => {
    if (!networkQuery.error) {
      return;
    }

    console.log(
      "DistributionNetworkProvider useNetwork error:",
      networkQuery.error,
    );
    // If there is no network with that id
    // Or you're a v1 user trying to get a v2 network (you get a 400)
    if (
      isBaseError(networkQuery.error) &&
      (networkQuery.error.status === 404 || networkQuery.error.status === 400)
    ) {
      // Redirect to the v1 application
      return window.location.replace(
        window.location.href.replace(window.location.origin, SHIPPER_V1_HOST),
      );
    }

    navigate("/not_found");
  }, [SHIPPER_V1_HOST, navigate, networkQuery.error]);

  // We show a loader for "loading" and "error", because the `onError` option to the hook will take care of
  // either redirecting to the v1 application or an error page in the event there's an error
  if (networkQuery.isPending || networkQuery.isError) {
    return <PageLoader />;
  }

  return (
    <DistributionNetworkProviderInner network={networkQuery.data}>
      {children}
    </DistributionNetworkProviderInner>
  );
};

function DistributionNetworkProviderInner({
  network,
  children,
}: {
  network: Network;
  children: ReactNode;
}) {
  return (
    <DistributionNetworkContext.Provider
      value={{
        distributionNetwork: {
          ...network,
          timezone: network.timezone || "UTC",
        },
      }}
    >
      <SEO />
      {children}
    </DistributionNetworkContext.Provider>
  );
}

export default DistributionNetworkProvider;
