import {
  Button,
  Flex,
  Tooltip,
  Box,
  useColorModeValue,
  useTheme,
  useToken,
  Text,
  Circle,
  MenuButton,
  MenuList,
  MenuOptionGroup,
  MenuItemOption,
  Menu,
  Portal,
} from "@chakra-ui/react";
import {
  faAngleDown,
  faCheck,
  faCircleInfo,
} from "@fortawesome/pro-regular-svg-icons";
import type { TooltipProps } from "recharts";
import {
  Area,
  AreaChart,
  Tooltip as RechartsTooltip,
  ResponsiveContainer,
} from "recharts";
import { transparentize } from "@chakra-ui/theme-tools";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

import {
  Card,
  FaIcon,
  qs,
  useDatadogRUM,
  useSearchParams,
} from "@stordco/fe-components";

import { useNetworkId } from "../../../../components/DistributionNetwork";
import { useFormatInPreferredTimezone } from "../../../../hooks";
import { formatNumber } from "../../../../lib/numbers";

import { WidgetTooltip } from "./WidgetTooltip";
import { StatButtonLink } from "../../StatButtonLink";
import { useWidgetData } from "./use-widget-data";
import { LoadingOverlay } from "../../LoadingOverlay";
import { WidgetErrorFetchingData } from "../../WidgetErrorFetchingData";

export function AcceptedOrders() {
  const [timeframe, setTimeframe] = useState(30);
  const networkId = useNetworkId();

  const { data, isLoading, isError, refetch } = useWidgetData(
    "accepted-orders",
    { timeframe },
  );

  const theme = useTheme();
  const [purple100] = useToken("colors", ["purple.100"]);
  const fill = useColorModeValue(
    purple100,
    transparentize(purple100, 0.24)(theme),
  );

  const navigate = useNavigate();

  const [params] = useSearchParams();
  const datadogRUM = useDatadogRUM();
  const formatInNetworkTimezone = useFormatInPreferredTimezone();

  const acceptedTodayCount = data?.history.reduce(
    (latest, datum) => {
      if (datum.date > latest.date) {
        return datum;
      }

      return latest;
    },
    { date: "" } as { date: string; count?: number },
  ).count;

  return (
    <Card>
      <Card.PaddedContent
        display="flex"
        flexDirection="column"
        gap={4}
        height="full"
      >
        <Flex alignItems="center" gap={2} alignSelf="start">
          <Card.Title>Accepted</Card.Title>

          <Tooltip label="Sales orders accepted to the system" placement="top">
            <FaIcon icon={faCircleInfo} boxSize={3} p={1} m={-1} />
          </Tooltip>
        </Flex>

        {isError ? (
          <WidgetErrorFetchingData onRetryClick={refetch} />
        ) : (
          <>
            <StatButtonLink
              number={formatNumber(acceptedTodayCount)}
              helpText="Accepted today"
              to={qs.stringifyUrl({
                url: `/distribution_networks/${networkId}/orders`,
                query: {
                  type: "sales",
                  // format in network timezone to ensure correct date
                  inserted_at_start_date: formatInNetworkTimezone(new Date(), {
                    formatStr: "yyyy-MM-dd",
                  }),
                  ...params,
                },
              })}
              statProps={{ size: "lg" }}
              onClick={() => {
                datadogRUM?.addAction("dashboard:view_orders", {
                  type: "accepted_today",
                  count: acceptedTodayCount,
                });
              }}
            />

            <Text textStyle="label">
              Accepted{" "}
              <Menu>
                <MenuButton
                  as={Button}
                  variant="link"
                  rightIcon={<FaIcon icon={faAngleDown} />}
                >
                  {`last ${timeframe} days`}
                </MenuButton>

                <Portal>
                  <MenuList>
                    <MenuOptionGroup
                      value={String(timeframe)}
                      onChange={(value) => {
                        datadogRUM?.addAction(
                          "dashboard:widget_query_changed",
                          {
                            widget: "accepted_orders",
                            newValue: value,
                          },
                        );

                        setTimeframe(parseInt(value as string));
                      }}
                      type="radio"
                    >
                      <MenuItemOption
                        value="7"
                        icon={<FaIcon icon={faCheck} />}
                      >
                        last 7 days
                      </MenuItemOption>

                      <MenuItemOption
                        value="30"
                        icon={<FaIcon icon={faCheck} />}
                      >
                        last 30 days
                      </MenuItemOption>

                      <MenuItemOption
                        value="90"
                        icon={<FaIcon icon={faCheck} />}
                      >
                        last 90 days
                      </MenuItemOption>
                    </MenuOptionGroup>
                  </MenuList>
                </Portal>
              </Menu>
            </Text>

            {data ? (
              <Box flex={1} rounded="base" overflow="hidden">
                <ResponsiveContainer minHeight={100}>
                  <AreaChart
                    data={data.history}
                    margin={{ top: 0, right: 0, bottom: 0, left: 0 }}
                    style={{ cursor: "pointer" }}
                    onClick={(data) => {
                      if (data?.activePayload?.[0]?.payload) {
                        datadogRUM?.addAction("dashboard:view_orders", {
                          type: "accepted_date",
                          count: data.activePayload[0].payload.count,
                        });

                        navigate(
                          qs.stringifyUrl({
                            url: `/distribution_networks/${networkId}/orders`,
                            query: {
                              type: "sales",
                              inserted_at_start_date:
                                data.activePayload[0].payload.date.substring(
                                  0,
                                  10,
                                ),
                              inserted_at_end_date:
                                data.activePayload[0].payload.date.substring(
                                  0,
                                  10,
                                ),
                              ...params,
                            },
                          }),
                        );
                      }
                    }}
                  >
                    <RechartsTooltip content={<ChartTooltip />} />

                    <Area
                      type="monotone"
                      dataKey="count"
                      name="Orders"
                      fill={fill}
                      fillOpacity={1}
                      strokeWidth={2}
                      stroke={fill}
                      animationDuration={800}
                    />
                  </AreaChart>
                </ResponsiveContainer>
              </Box>
            ) : null}
          </>
        )}

        {isLoading ? <LoadingOverlay /> : null}
      </Card.PaddedContent>
    </Card>
  );
}

function ChartTooltip({ payload }: TooltipProps<number, string>) {
  const format = useFormatInPreferredTimezone();

  if (!payload?.length) {
    return null;
  }

  const [
    {
      payload: { date },
      name,
      color,
      value,
    },
  ] = payload;

  return (
    <WidgetTooltip>
      <Flex direction="column" gap={1}>
        <Text textStyle="tooltip">{format(date, { type: "dateWithDay" })}</Text>

        <Flex alignItems="center" gap={2}>
          <Circle bg={color} size="2" />

          <Text textStyle="caption">{name}</Text>

          <Text textStyle="tooltip" ml="auto">
            {formatNumber(value)}
          </Text>
        </Flex>
      </Flex>
    </WidgetTooltip>
  );
}
