import { useHotkeys } from "react-hotkeys-hook";

import { useConfig, useUserDetails } from "../../hooks";
import { useDatadogRUM } from "../Datadog";

export const AdminKeyboardShortcutController = (): null => {
  const { data: userDetails } = useUserDetails();
  const datadogRum = useDatadogRUM();
  const { ADMIN_HOST } = useConfig();

  useHotkeys("mod+shift+o", () => {
    if (userDetails?.isAdmin) {
      datadogRum?.addAction("hotkey:open_admin");
      window.location.href = ADMIN_HOST;
    }
  });

  return null;
};
