import { useQuery, keepPreviousData } from "@tanstack/react-query";

import { qs, useApi, useSearchParams } from "@stordco/fe-components";

import { useDistributionNetwork } from "../../../../components/DistributionNetwork";

type Widget =
  | "open-orders"
  | "shipped-orders"
  | "accepted-orders"
  | "delivery-tracking"
  | "origins-and-destinations"
  | "new-order-exceptions";

function enforceArray(
  param: undefined | null | string | string[],
): undefined | null | string[] {
  if (typeof param === "string") {
    return [param];
  }

  return param ?? undefined;
}

type WidgetResponse<T extends Widget> = T extends "open-orders"
  ? {
      total_count: number;
      total_late_count: number;
      accepted_last_5_days: Array<{
        count: number;
        late_count: number;
        date: string;
      }>;
    }
  : T extends "shipped-orders"
  ? {
      history: Array<{
        count: number;
        date: string;
      }>;
    }
  : T extends "accepted-orders"
  ? {
      history: Array<{
        count: number;
        date: string;
      }>;
    }
  : T extends "origins-and-destinations"
  ? {
      facility_counts: Array<{
        facility_id: string;
        count: number;
      }>;
      destination_counts: Array<{
        state: string;
        count: number;
      }>;
      international_destination_count: number;
      total_count: number;
    }
  : T extends "delivery-tracking"
  ? {
      in_transit_count: number;
      out_for_delivery_count: number;
      delivered_today_count: number;
    }
  : T extends "new-order-exceptions"
  ? {
      backordered_count: number;
      exception_counts: Array<{
        exception_title: string;
        count: number;
      }>;
    }
  : never;

export function useWidgetData<T extends Widget>(
  widget: T,
  params?: Record<string, any>,
) {
  const api = useApi();
  const { distributionNetwork } = useDistributionNetwork();

  const [searchParams] = useSearchParams();

  // facility_id and channel_id must be arrays or the backend no worky
  const globalFilterParams = {
    facility_id: enforceArray(searchParams["facility_id"]),
    channel_category: searchParams["channel_category"],
    channel_id: enforceArray(searchParams["channel_id"]),
    shipment_type: searchParams["shipment_type"],
  };

  return useQuery({
    queryKey: [
      "Dashboard",
      distributionNetwork.network_id,
      widget,
      {
        ...globalFilterParams,
        ...params,
        timezone: distributionNetwork.timezone,
      },
    ],
    queryFn: () =>
      api.get<WidgetResponse<T>>({
        url: `/v1/search/${distributionNetwork.network_id}/dashboard`,
        headers: {
          "x-timezone": distributionNetwork.timezone,
        },
        params: {
          widget,
          ...params,
          ...globalFilterParams,
        },
        baseOverrides: {
          paramsSerializer: (params) =>
            qs.stringify(params, { arrayFormat: "bracket" }),
        },
      }),
    // While loading data for new filters, keep showing data for previous filters
    placeholderData: keepPreviousData,

    // Evict cache immediately, so when we go back to a previous set of filters we don't want to show what we had before, we want to load new data
    gcTime: 0,

    refetchInterval: 5 * 60 * 1000,
  });
}
