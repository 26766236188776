import { useLDClient } from "launchdarkly-react-client-sdk";
import { useState, useEffect } from "react";
import type { ReactNode } from "react";

import {
  identifyUserLD,
  PageLoader,
  useUserDetails,
} from "@stordco/fe-components";

import useDistributionNetwork from "../DistributionNetwork/useDistributionNetwork";

const LaunchDarklyCustomUserAttributes = ({
  children,
}: {
  children: ReactNode;
}) => {
  const [isSettingUserAttributes, setIsSettingUserAttributes] = useState(true);
  const { distributionNetwork } = useDistributionNetwork();
  const { data: userDetails } = useUserDetails();
  const ldClient = useLDClient();

  useEffect(() => {
    if (!ldClient || !userDetails || !distributionNetwork.network_id) {
      return;
    }

    identifyUserLD(ldClient, userDetails, {
      network: distributionNetwork.network_id,
      networkVersion: 2,
    })
      .catch(console.error)
      .finally(() => setIsSettingUserAttributes(false));
  }, [ldClient, userDetails, distributionNetwork?.network_id]);

  if (isSettingUserAttributes) {
    return <PageLoader />;
  }

  return <>{children}</>;
};

export default LaunchDarklyCustomUserAttributes;
