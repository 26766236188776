import {
  Button,
  Menu,
  MenuButton,
  MenuDivider,
  MenuGroup,
  MenuItem,
  MenuList,
  useBreakpointValue,
} from "@chakra-ui/react";
import { faAngleDown } from "@fortawesome/pro-regular-svg-icons";
import { Link as RouterLink, Outlet } from "react-router-dom";

import {
  FaIcon,
  Header,
  LinkTabs,
  PageLoader,
  useUserDetails,
} from "@stordco/fe-components";

import { useNetworkId } from "../../components/DistributionNetwork";
import { Suspense } from "react";

export default function Dashboard() {
  const showFullTitle = useBreakpointValue(
    { base: false, sm: true },
    { ssr: false },
  );

  const { data: userDetails } = useUserDetails();
  const networkId = useNetworkId();

  return (
    <>
      <Header
        title={
          showFullTitle && userDetails.firstName
            ? `Welcome back, ${userDetails.firstName}!`
            : "Welcome back!"
        }
        seoTitle="Dashboard"
        actions={
          <Menu>
            <MenuButton
              as={Button}
              rightIcon={<FaIcon icon={faAngleDown} />}
              variant="outline"
              colorScheme="gray"
            >
              Actions
            </MenuButton>

            <MenuList>
              <MenuGroup title="Create order">
                <MenuItem
                  as={RouterLink}
                  to={`/distribution_networks/${networkId}/orders/create_sales_order`}
                >
                  Sales order
                </MenuItem>

                <MenuItem
                  as={RouterLink}
                  to={`/distribution_networks/${networkId}/orders/create_purchase_order`}
                >
                  Purchase order
                </MenuItem>

                <MenuItem
                  as={RouterLink}
                  to={`/distribution_networks/${networkId}/orders/create_transfer_order`}
                >
                  Transfer order
                </MenuItem>
              </MenuGroup>

              <MenuDivider />

              <MenuGroup title="Create product">
                <MenuItem
                  as={RouterLink}
                  to={`/distribution_networks/${networkId}/product_catalog`}
                  state={{ isItemModalOpen: true }}
                >
                  Items
                </MenuItem>

                <MenuItem
                  as={RouterLink}
                  to={`/distribution_networks/${networkId}/product_catalog`}
                  state={{ isBundleModalOpen: true }}
                >
                  Bundle
                </MenuItem>
              </MenuGroup>
            </MenuList>
          </Menu>
        }
        mb={4}
      />

      <LinkTabs mb={4}>
        <LinkTabs.TabList>
          <LinkTabs.Tab to="" end>
            Fulfillment
          </LinkTabs.Tab>

          <LinkTabs.Tab to="inventory" end>
            Inventory
          </LinkTabs.Tab>
        </LinkTabs.TabList>
      </LinkTabs>

      <Suspense fallback={<PageLoader />}>
        <Outlet />
      </Suspense>
    </>
  );
}
