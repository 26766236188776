import { createContext } from "react";

import type { Network } from "./useNetwork";

export type DistributionNetworkContextValue = {
  distributionNetwork: Omit<Network, "timezone"> & { timezone: string };
};

const DistributionNetworkContext =
  createContext<DistributionNetworkContextValue | null>(null);

export default DistributionNetworkContext;
