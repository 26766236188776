import type { ReactNode } from "react";
import { Navigate, useParams } from "react-router-dom";

import { useCan } from "../../hooks";

export function RequireNetworkPermission({
  children,
  permission,
  redirectTo = "/forbidden",
}: {
  children: ReactNode;
  permission: string;
  redirectTo?: string;
}) {
  const { id: networkId } = useParams();

  if (!networkId) {
    throw new Error(
      "Missing network id. Needs to be used in a network route with an `:id` variable.",
    );
  }

  if (!useCan(permission, [networkId])) {
    return <Navigate to={redirectTo} replace />;
  }

  return <>{children}</>;
}
