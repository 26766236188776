import type { ReactNode } from "react";
import { Navigate, useLocation, useParams } from "react-router-dom";

import { useAuthentication, useConfig } from "@stordco/fe-components";
import useMe from "../hooks/cloudApi/useMe";

const redirectTo = (url: string): null => {
  window.location.assign(url);
  return null;
};

export const CloudAuthHydrator = ({
  intendedUrl = "/",
  children,
}: {
  children: ReactNode;
  intendedUrl?: string;
}) => {
  const { ADMIN_HOST } = useConfig();
  const { id: networkId } = useParams();
  const { isAuthenticated } = useAuthentication();
  const location = useLocation();

  const shouldCheckMe = isAuthenticated;

  const meQuery = useMe();

  if (!shouldCheckMe) return <>{children}</>;

  if (!meQuery.isSuccess || meQuery.isLoading) return null;

  const me = meQuery.data.data;
  if (
    me.type === "auth0" ||
    (me.type === "user" && me.user.status === "setup")
  ) {
    if (location.pathname !== "/onboarding") {
      return <Navigate to={`/onboarding`} />;
    }

    return <>{children}</>;
  }

  const hasAdminApp = meQuery.data?.data.organizations.some((org) =>
    org.apps.some((app) => app.alias === "oms_admin"),
  );

  if (hasAdminApp && !networkId) {
    // redirect to admin because they haven't selected a network
    // in the future, we could also add a network selector page here in shipper
    return redirectTo(ADMIN_HOST);
  }

  if (!networkId) {
    // find the first org that has the oms app
    const firstNetworkId = meQuery.data?.data.organizations
      .find((org) => org.apps.some((app) => app.alias === "oms"))
      ?.apps.find((app) => app.alias === "oms")?.realms[0].resource_id;

    if (!firstNetworkId) {
      // should be impossible, but they have no access
      console.error("User has no access to any networks");
      return <Navigate to={"/forbidden"} />;
    }

    return (
      <Navigate to={`/distribution_networks/${firstNetworkId}${intendedUrl}`} />
    );
  }

  return <>{children}</>;
};
